var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('awesome-table',{attrs:{"labels":_vm.labels,"items":_vm.warehouses,"options":{
                sort: true,
                filters: false,
                csvExport: false,
                isCard: false
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters},on:{"row-click":function($event){return _vm.$router.push(("/warehouses/" + ($event.id) + "/edit"))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/warehouses/" + ($event.id) + "/edit"))},"add-button-click":function($event){return _vm.$router.push('/warehouses/add')},"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.is_default",fn:function(ref){
            var value = ref.value;
return [_c('span',{class:_vm.getBadgeClass(value)},[_vm._v("\n                    "+_vm._s(value === true ? 'YES' : 'NO')+"\n                ")])]}},{key:"items.warehouse_users",fn:function(ref){
            var item = ref.item;
return _vm._l((item.users),function(user,index){return _c('span',{key:index},[_vm._v("\n                    "+_vm._s(((user.first_name) + "\n                        " + (user.last_name)))+"\n                    "),_c('br')])})}},{key:"items.actions",fn:function(ref){
                        var item = ref.item;
return [(!item.is_default)?_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDeleteWarehouse(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }