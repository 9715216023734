<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <awesome-table
                :labels="labels"
                :items="warehouses"
                :options="{
                    sort: true,
                    filters: false,
                    csvExport: false,
                    isCard: false
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                @row-click="$router.push(`/warehouses/${$event.id}/edit`)"
                @mouse-wheel-click="$openNewTab(`/warehouses/${$event.id}/edit`)"
                @add-button-click="$router.push('/warehouses/add')"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #[`items.is_default`]="{ value }">
                    <span :class="getBadgeClass(value)">
                        {{ value === true ? 'YES' : 'NO' }}
                    </span>
                </template>
                <template #[`items.warehouse_users`]="{ item }">
                    <span v-for="(user, index) in item.users" :key="index">
                        {{
                            `${user.first_name}
                            ${user.last_name}`
                        }}
                        <br />
                    </span>
                </template>
                <template #[`items.actions`]="{ item }">
                    <a
                        v-if="!item.is_default"
                        href="#"
                        class="action-icon"
                        @click.stop.prevent="onDeleteWarehouse(item.id)"
                    >
                        <i class="far fa-trash-alt" />
                    </a>
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Warehouses',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Warehouses',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Warehouses',
                    active: true
                }
            ],
            labels: [
                'name',
                'is_default',
                {
                    text: 'Users',
                    value: 'warehouse_users',
                    sortable: false
                },
                'state',
                {
                    value: 'actions',
                    sortable: false,
                    width: '5%',
                    filterable: false
                }
            ],
            warehouses: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            selected: [],
            isLoading: false
        };
    },

    async created() {
        this.fetchWarehouses();
    },

    methods: {
        ...mapActions({
            getWarehouses: 'warehouses/index',
            deleteWarehouse: 'warehouses/delete',
        }),

        async fetchWarehouses() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getWarehouses(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.warehouses = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchWarehouses();
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchWarehouses();
        },

        getBadgeClass(value) {
            let color = 'danger';

            if (value === true) {
                color = 'success';
            }

            return `badge bg-soft-${color} text-${color}`;
        },

        async onDeleteWarehouse(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this item? All states will be assigned to default warehouse.', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteWarehouse(id);

                this.warehouses = this.warehouses.filter(
                    item => item.id !== id
                );

                this.$toaster('Warehouse has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
